import {Address} from 'app/shared/models/relation/address';
import {Company} from 'app/shared/models/entity/company';
import _ from 'lodash';
import {Document} from '../general/document';
import {Transaction} from 'app/shared/models/general/transaction';
import {Country} from 'app/shared/models/general/country';
import {Email} from 'app/shared/models/mail/email';
import {Relation} from '../relation/relation.class';
import moment from 'moment';
import {Product} from 'app/shared/models/article/product';
import {Service} from 'app/shared/models/article/service';
import {ArticleTypeEnum} from 'app/shared/models/article/articletype.enum';
import {CombinedArticle} from '../article/combinedarticle';
import {Contact} from '../relation/contact';
import {User} from '../entity/user';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {VatService} from "../../../core/services/vat.service";
import {getWhitelabelEnvironment} from "../../../core/logic/whitelabel.logic";
import {ICombinedArticle, IOrder, IOrderInput, IOrderSubline, IOrderSublineInput, IProduct, IService} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";


export class Order implements IOrder {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';

  quoteId: number = 0;
  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressZip: string = '';
  companyAddressCity: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;

  get company_address(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  company: Company;
  bankName: string = '';
  bankBic: string = '';
  bankAccountNumber: string = '';

  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressZip: string = '';
  relationAddressCity: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;

  get customer_address(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  relation: Relation;

  value: number = 0;
  vatValue: number = 0;
  valueIncl: number = 0;
  intnote: string = '';
  note: string = '';
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  status: OrderStatusEnum = OrderStatusEnum.CONCEPT;

  get Status(): string {
    switch (this.status) {
      case OrderStatusEnum.CONCEPT:
        return translate(marker("label.salesOrder.status.concept", "Concept"));
      case OrderStatusEnum.UNPROCESSED:
        return translate(marker("label.salesOrder.status.unprocessed", "Niet verwerkt"));
      case OrderStatusEnum.PROCESSED:
        return translate(marker("label.salesOrder.status.processed", "Verwerkt"));
      case OrderStatusEnum.PARTLYINVOICED:
        return translate(marker("label.salesOrder.status.partlyinvoiced", "Gedeeltelijk gefactureerd"));
      case OrderStatusEnum.INVOICED:
        return translate(marker("label.salesOrder.status.invoiced", "Gefactureerd"));
    }
  }

  paymentReference: string = '';
  paymentReferenceStruct: boolean;

  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  userId: number = 0;
  userName: string = '';
  userPhone: string = '';
  userMail: string = '';
  contactId: number = 0;
  contactName: string = '';
  contactPhone: string = '';
  contactMail: string = '';
  user: User;
  contact: Contact;
  relationReference: string = '';
  guestToken: string = '';

  get GuestToken() {
    return `${getWhitelabelEnvironment().app.url}/guest/ORD+${this.guestToken}`;
  }

  guestEnabled: boolean = false;
  vatCode: string = "";

  sublines: OrderSubline[] = [];
  documents: Document[] = [];
  transactions: Transaction[] = [];
  emails: Email[] = [];

  get Valid(): boolean {
    return (
      this.companyId != null &&
      this.companyId > 0 &&
      this.companyName != null &&
      this.companyName.trim() != '' &&
      this.companyVatNumber != null &&
      this.companyVatNumber.trim() != '' &&
      this.relationId != null &&
      this.relationId > 0 &&
      this.relationName != null &&
      this.relationName.trim() != '' &&
      this.value !== null &&
      this.vatValue !== null &&
      this.valueIncl !== null &&
      this.date !== null
    );
  }

  constructor(order: IOrder = null) {
    if (order === null) {
      return;
    }

    constructFromInterface(this, order);

    if (order.date) {this._date = GetDateScalar(order.date);}
    if (order.created) {this._created = GetDateTimeScalar(order.created);}
    if (order.updated) {this._updated = GetDateTimeScalar(order.updated);}
    if (order.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == order.companyAddressCountryId));
    }
    if (order.company) {
      this.company = new Company(order.company);
    }
    if (order.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == order.relationAddressCountryId));
    }
    if (order.relation) {
      this.relation = new Relation(order.relation);
    }
    if (order.user) {
      this.user = new User(order.user);
    }
    if (order.contact) {
      this.contact = new Contact(order.contact);
    }

    if (order.sublines?.length > 0) {
      this.sublines = order.sublines.map((x) => new OrderSubline(x));
    }
    if (order.documents?.length > 0) {
      this.documents = order.documents.map((x) => new Document(x));
    }
    if (order.transactions?.length > 0) {
      this.transactions = order.transactions.map((x) => new Transaction(x));
    }
    if (order.emails?.length > 0) {
      this.emails = order.emails.map((x) => new Email(x));
    }
  }

  public calculateValue(): void {
    this.value = _.sumBy(this.sublines, (x) => +x.price);
    this.valueIncl = _.sumBy(this.sublines, (x) => +x.priceIncl);
    this.vatValue = _.sumBy(this.sublines, (x) => +x.vatprice);
  }

  public static new(): Order {
    return new Order({
      id: null,
      subscriberId: null,
      quoteId: null,
      number: null,
      Number: null,
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressZip: null,
      companyAddressCity: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      companyAddressCountry: null,
      company: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressZip: null,
      relationAddressCity: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      relationAddressCountry: null,
      relation: null,
      value: 0,
      vatValue: 0,
      valueIncl: 0,
      intnote: null,
      note: null,
      date: SetDateScalar(new Date()),
      status: OrderStatusEnum.CONCEPT,
      paymentReference: null,
      paymentReferenceStruct: true,
      created: null,
      updated: null,
      sublines: [],
      documents: [],
      transactions: [],
      emails: [],
      bankAccountNumber: null,
      bankBic: null,
      bankName: null,
      userId: null,
      contactId: null,
      userName: null,
      userPhone: null,
      userMail: null,
      contactName: null,
      contactPhone: null,
      contactMail: null,
      user: null,
      contact: null,
      relationReference: null,
      guestToken: null,
      guestEnabled: true,
      vatCode: null,
    });
  }

  public convertToInput(): IOrderInput {
    return {
      id: this.id,
      quoteId: this.quoteId,
      companyId: this.companyId,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressZip: this.companyAddressZip,
      companyAddressCity: this.companyAddressCity,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: this.companyAddressCountryId,
      relationId: this.relationId,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressZip: this.relationAddressZip,
      relationAddressCity: this.relationAddressCity,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: this.relationAddressCountryId,
      intnote: this.intnote,
      note: this.note,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      status: this.status,
      value: this.value,
      vatValue: this.vatValue,
      valueIncl: this.valueIncl,
      paymentReference: this.paymentReference,
      paymentReferenceStruct: this.paymentReferenceStruct,
      bankAccountNumber: this.bankAccountNumber,
      bankBic: this.bankBic,
      bankName: this.bankName,
      userId: this.userId ? +this.userId : null,
      contactId: this.contactId ? +this.contactId : null,
      userName: this.userName,
      userPhone: this.userPhone,
      userMail: this.userMail,
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      contactMail: this.contactMail,
      relationReference: (this.relationReference ?? '').trim().length > 0 ? this.relationReference : null,
      guestEnabled: !!this.guestEnabled,
      vatCode: this.vatCode,
    };
  }
}

export class OrderSubline implements IOrderSubline {
  id: number = 0;
  subscriberId: number = 0;
  orderId: number = 0;

  articleTypeId: number = 0;

  get ArtType(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.article.product', 'product'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.article.service', 'Dienst'));
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.article.combinedArticle', 'Gecombineerd artikel'));
    }
  }

  get ArtTypeButton(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.article.product', 'product'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.article.service', 'Dienst'));
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.article.combinedArticle', 'Gecombineerd artikel'));
    }
  }

  get ArtTypeSku(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.product.sku', 'Sku'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.service.sku', 'Sku'))
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.combinedArticle.sku', 'Sku'))
    }
  }

  get ArtTypeName(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.product.name', 'Naam'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.service.name', 'Naam'))
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.combinedArticle.name', 'Naam'))
    }
  }

  articleId: number = 0;
  articleSku: string = '';
  articleName: string = '';
  article: Product | Service | CombinedArticle;
  vatCode: string = "";
  amount: number = 0;
  unitPrice: number = 0;
  basePrice: number = 0;
  discount: number = 0;
  price: number = 0;
  priceIncl: number = 0;
  vatprice: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;
  unit: string = '';

  get Valid(): boolean {
    return (
      this.articleTypeId != null &&
      (this.articleName ?? '').trim().length > 0 &&
      this.amount !== null &&
      this.unitPrice !== null &&
      this.basePrice !== null &&
      this.discount !== null &&
      this.price !== null &&
      this.priceIncl !== null &&
      this.vatprice !== null &&
      !_.isNil(this.vatCode)
    );
  }

  constructor(subline: IOrderSubline) {
    constructFromInterface(this, subline);

    if (subline.created) {this._created = GetDateTimeScalar(subline.created);}
    if (subline.updated) {this._updated = GetDateTimeScalar(subline.updated);}
    if (subline.article) {
      switch (subline?.articleTypeId) {
        case ArticleTypeEnum.PRODUCT:
          this.article = new Product(subline.article as IProduct);
          break;
        case ArticleTypeEnum.SERVICE:
          this.article = new Service(subline.article as IService);
          break;
        case ArticleTypeEnum.COMBINEDARTICLE:
          this.article = new CombinedArticle(subline.article as ICombinedArticle);
          break;
      }
    }
  }

  public static new(): OrderSubline {
    return new OrderSubline({
      id: null,
      subscriberId: null,
      orderId: null,
      articleTypeId: ArticleTypeEnum.PRODUCT,
      articleId: null,
      articleSku: null,
      articleName: null,
      vatCode: null,
      article: null,
      amount: 1,
      unitPrice: 0,
      basePrice: 0,
      discount: 0,
      price: 0,
      priceIncl: 0,
      vatprice: 0,
      note: null,
      created: null,
      updated: null,
      order: null,
      unit: null,
    });
  }

  public convertToInput(): IOrderSublineInput {
    return {
      id: this.id,
      articleTypeId: +this.articleTypeId,
      articleId: +this.articleId,
      articleName: this.articleName,
      articleSku: this.articleSku,
      amount: +this.amount,
      discount: +this.discount,
      price: +this.price,
      basePrice: +this.basePrice,
      priceIncl: +this.priceIncl,
      unitPrice: +this.unitPrice,
      vatprice: +this.vatprice,
      note: this.note,
      vatCode: this.vatCode,
      order: this.order,
      unit: (this.unit ?? '').trim().length > 0 ? '' + this.unit : null,
    };
  }

  calculateSublineValuesFromAmount(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.basePrice = +(this.basePrice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);
    this.discount = +(this.discount ?? 0);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = this.unitPrice ?? 0;
    this.basePrice = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFomUnitPrice(): void {
    const vat = VatService.Get(this.vatCode);
    this.basePrice = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromBaseSalesPrice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.basePrice = +(this.basePrice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);
    this.discount = +(this.discount ?? 0);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromDiscount(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.basePrice = +(this.basePrice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);
    this.discount = +(this.discount ?? 0);

    const vat = VatService.Get(this.vatCode);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromSalesPrice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.basePrice = +(this.basePrice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);
    this.discount = +(this.discount ?? 0);

    const vat = VatService.Get(this.vatCode);
    this.basePrice = +(+this.price + +(this.discount ?? 0)).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  calculateSublineValuesFromSalesPriceIncl(): void {
    this.price = +(this.price ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.basePrice = +(this.basePrice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.discount = +(this.discount ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(vat.percentage ? +this.priceIncl / (+vat.percentage / 100 + 1) : +this.priceIncl).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.basePrice = +(+this.price + +(this.discount ?? 0)).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);
  }
}

export enum OrderStatusEnum {
  CONCEPT = 1,
  UNPROCESSED = 2,
  PROCESSED = 3,
  INVOICED = 4,
  PARTLYINVOICED = 5
}
